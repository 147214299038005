<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table show-entire">
        <div class="card-body">
          <!-- Table Header -->
          <div class="page-table-header mb-2">
            <div class="row align-items-center">
              <div class="col">
                <div class="doctor-table-blk">
                  <h3>{{ strTitleList }}</h3>
                  <div class="doctor-search-blk">
                    <div class="top-nav-search table-search-blk">
                      <form @submit.prevent="handleSearch">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Buscar"
                          v-model="strSearchQuery"
                        />
                        <a class="btn"
                          ><img src="@/assets/img/icons/search-normal.svg" alt=""
                        /></a>
                      </form>
                    </div>
                    <div class="add-group">
                      <router-link
                        v-if="bAddButton"
                        :to="{ name: `${strRoute}/new` }"
                        class="btn btn-primary add-pluss ms-2">
                        <img src="@/assets/img/icons/plus.svg" alt=""/>
                      </router-link>
                      <a
                        v-if="bRefreshButton"
                        href="javascript:;"
                        class="btn btn-primary doctor-refresh ms-2"
                        @click="handleRefresh">
                        <img src="@/assets/img/icons/re-fresh.svg" alt=""/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <!-- Table -->
          <div class="table-responsive">
            <a-table
              class="table border-0 custom-table comman-table datatable mb-0"
              :columns="lstColumnsData"
              :dataSource="handleFilterData"
              :rowKey="record => record[strPrimaryKey]"
              :rowSelection="handleRowSelection"
              :pagination="objPagination"
              :loading="bSpinner">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'id_historia_clinica'">
                  <router-link :to="{ name: `${strRoute}/edit`, params: { recordId: record[strPrimaryKey] } }" >
                    MEDHIS-#{{ record[strPrimaryKey] }}
                  </router-link>
                </template>
                <template v-else-if="column.key === 'action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:;"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link :to="{ name: `${strRoute}/edit`, params: { recordId: record[strPrimaryKey] } }" 
                          class="dropdown-item" >
                          <i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Editar</router-link>
                        <!--a class="dropdown-item"
                          href="javascript:;"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_patient">
                          <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                        </a-->
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
          <!-- /Table -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bLoading: { type: Boolean, default: false },
    bSelectRow: { type: Boolean, default: false },
    bAddButton: { type: Boolean, default: true },
    bRefreshButton: { type: Boolean, default: true },
    strTitleList: { type: String, default: "Listado de información" },
    strRoute: { type: String, default: '' },
    strPrimaryKey: { type: String, default: 'id' },
    objPagination: { type: Object, default: () => ({ pageSize: 15 }) },
    lstColumns: { type: Array, default: () => [] },
    lstData: { type: Array, default: () => [], required: true }
  },
  emits: ["update:modelValue", "select", "refresh"],
  data() {
    return {
      bSpinner: false,
      strSearchQuery: "",
      lstDataTable: this.lstData,
      lstColumnsData: [],
      lstRowSelected: [],
    };
  },
  watch: {
    bLoading: {
      handler(bNewValue) {
        this.bSpinner = bNewValue;
      },
      immediate: true,
    },
    lstColumns: {
      handler(lstNewValue) {
        this.handleCreateColumns(lstNewValue);
      },
      immediate: true,
    },
    lstData: {
      handler(lstNewValue) {
        this.lstDataTable = lstNewValue;
      },
      immediate: true,
    },
  },
  computed: {
    handleFilterData() {
      if (!this.strSearchQuery) return this.lstDataTable;
      const query = this.strSearchQuery.toLowerCase();

      return this.lstDataTable.filter((objData) => {
        return this.lstColumns.some((objColumn) => {
          if (!objColumn.bFilter) return false;
          const strValue = objData[objColumn.strDataIndex];
          const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
          return strValueSearch.includes(query);
        });
      });
    },
    handleRowSelection(){
      if(!this.bSelectRow) return null;
      return {
        selectedRowKeys: this.lstRowSelected,
        onChange: this.handleRowSelectionChange,
      }
    },
    handlePagination(){
      return this.objPagination;
    }
  },
  created(){
    
  },
  methods: {
    handleRefresh() {
      this.$emit('refresh');
    },
    handleSearch() {
      // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      this.$emit('update:modelValue', this.strSearchQuery);
    },
    handleRowSelectionChange(selectedRowKeys) {
      this.lstRowSelected = selectedRowKeys;
      this.$emit('select', selectedRowKeys);
    },
    handleCreateColumns(lstColumns){
      this.lstColumnsData = lstColumns.map((objColumn) => ({
        title: objColumn.strTitle,
        dataIndex: objColumn.strDataIndex,
        key: objColumn.strKey || objColumn.strDataIndex,
        sorter: objColumn.bSorter !== false ? {
          compare: (a, b) => {
            const aValue = a[objColumn.strDataIndex];
            const bValue = b[objColumn.strDataIndex];

            // Verificar si los valores son cadenas y convertir a minúsculas
            const aValueStr = (typeof aValue === 'string' && aValue !== null ) ? aValue.toLowerCase() : aValue;
            const bValueStr = (typeof bValue === 'string' && bValue !== null ) ? bValue.toLowerCase() : bValue;

            // Comparar valores
            if (aValueStr < bValueStr) return -1;
            if (aValueStr > bValueStr) return 1;
            return 0;
          },
        } : undefined,
        class: objColumn.strClass || "",
      }));
    },
  }
};
</script>
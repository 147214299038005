<template>
  <physiocloud-component-list
      :bLoading="bLoading"
      :bSelectRow="false"
      :bAddButton="false"
      :strTitleList="'Tickets'"
      :strRoute="'/ticket'"
      :strPrimaryKey="'id_ticket'"
      :objPagination="{ pageSize: 15 }"
      :lstColumns="lstColumns"
      :lstData="lstDataTable"
      @refresh="handleDataInit"
    />
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstEstatusTicket = [
  { id: "1", text: 'Todos' },
];

const lstColumns = [
  { strTitle: "Ticket", strDataIndex: "strTicket", strKey: "strTicket", bFiler: true },
  { strTitle: "Total", strDataIndex: "strTotal", strKey: "strTotal", bFiler: true },
  { strTitle: "Forma de Pago", strDataIndex: "strFormaPago", strKey: "strFormaPago", bFiler: true },
  { strTitle: "Estatus", strDataIndex: "strEstatus", strKey: "strEstatus", bFiler: true },
  { strTitle: "Fecha", strDataIndex: "strFecha", strKey: "strFecha", bFiler: true },
  //{ strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
];

export default {
  props:{
    parentId: {
      type: String,
      default: null,
      required: true,
    },
    lstData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bLoading: false,
      lstEstatusTicket,
      lstColumns: lstColumns,
      lstDataTable: [],
    };
  },
  watch: {
    lstData: {
      handler: function (val) {
        this.lstDataTable = val;
      },
      deep: true,
    },
  },
  async created() {
    
  },
  methods: {
    async handleDataInit(strRouteApi = '/tickets') {
      try{
        this.bLoading = true;
        const objResponse = await axios.get(`/pacientes/${this.parentId}/tickets`);
        this.lstDataTable = objResponse.data.map((objResponse) => ({
          ...objResponse,
          strTicket: `TCK-#${objResponse.id_ticket}`,
          strTotal: `$${objResponse.total ?? '0.00'}`,
          strFormaPago: `${objResponse.forma_pago?.nombre ?? ''}`,
          strEstatus: `${objResponse.estatus_ticket?.nombre ?? ''}`,
          strFecha: objResponse.fecha ? format(new Date(objResponse.fecha), 'dd/MM/yyyy HH:mm:ss') : '',
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.bLoading = false;
      }
    },
  }
};
</script>

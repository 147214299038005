import axios from '@/axios';
import jwtDecode from 'jwt-decode';

export default {
  async login(email, password, captchaToken) {
    try {
      let objUser = {
        usuario: email,
        contrasena: password,
        captchaToken: captchaToken
      }
      const response = await axios.post(`/usuario/login`, objUser);
      if (response.data.token) {
        localStorage.setItem('objToken', response.data.token);
        localStorage.setItem('objUser', JSON.stringify(response.data.usuario));
        return { success: true };
      } else {
        console.error("Error en la solicitud de login:", response.data);
        return { success: false, error: 'Credenciales inválidas' };
      }
    } catch (error) {
      console.error("Error en la solicitud de login:", error);
      if (error.response) {
        return { success: false, error: error.response.data.error };
      } else if (error.request) {
        console.error("Error en la solicitud de login:", error);
        return { success: false, error: 'network_error' };
      } else {
        console.error("Error en la solicitud de login:", error);
        return { success: false, error: 'network_error' };
      }
    }
  },

  async logout() {
    try {
      localStorage.removeItem('objToken');
      localStorage.removeItem('objUser');
      const response = await axios.post('/usuario/logout');
      if (response.status === 200) {
        return { success: true };
      }
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      return { success: false, error: 'logout_error' };
    }
  },

  async verifyToken() {
    try {
      const response = await axios.get('/usuario/verify-token');
      return response.status === 200;
    } catch (error) {
      return false;
    }
  },
  getToken() {
    return localStorage.getItem('objToken');
  }
};

<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración General</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Genero" active>
                  <GenderList />
                </b-tab>
                <b-tab title="Estado civil">
                  <span>
                    <MaritalStatusList />
                  </span>
                </b-tab>
                <b-tab title="Ocupación">
                  <span>
                    <ProfessionList />
                  </span>
                </b-tab>
                <b-tab title="Esquema Laboral">
                  <span>
                    <WorkTypeList />
                  </span>
                </b-tab>
                <b-tab title="Parentesco">
                  <span>
                    <RelationshipList />
                  </span>
                </b-tab>
                <b-tab title="Actividad Deportiva">
                  <span>
                    <SportActivityList />
                  </span>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GenderList from '../gender/GenderList.vue';
import MaritalStatusList from '../marital-status/MaritalStatusList.vue';
import ProfessionList from '../profession/ProfessionList.vue';
import RelationshipList from '../relationship/RelationshipList.vue';
import SportActivityList from '../sport-activity/SportActivityList.vue';
import WorkTypeList from '../work-type/WorkTypeList.vue';

export default {
  data() {
    return {};
  },
  components: {
    GenderList,
    MaritalStatusList,
    RelationshipList,
    ProfessionList,
    WorkTypeList,
    SportActivityList,
  },
};
</script>

<template>
    <div class="row">
      <div class="col-sm-12">
        <div class="card card-table show-entire">
          <div class="card-body">
            <!-- Table Header -->
            <div class="page-table-header mb-2">
              <div class="row align-items-center">
                <div class="col">
                  <div class="doctor-table-blk">
                    <h3>Pruebas fisicas</h3>
                    <div class="doctor-search-blk">
                      <div class="top-nav-search table-search-blk">
                        <form @submit.prevent="handleSearch">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Buscar prueba fisica"
                            v-model="strSearchQuery"
                          />
                          <a class="btn"
                            ><img src="@/assets/img/icons/search-normal.svg" alt=""
                          /></a>
                        </form>
                      </div>
                      <div class="add-group">
                        <router-link
                          :to="{ name: 'add-patient-test', params: { id: id, historyId: historyId, appointmentId: appointmentId } }"
                          class="btn btn-primary add-pluss ms-2"
                          ><img src="@/assets/img/icons/plus.svg" alt=""
                        /></router-link>
                        <a
                          href="javascript:;"
                          class="btn btn-primary doctor-refresh ms-2"
                          @click="getTest"
                          ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Table Header -->

            <!-- Table -->
            <div class="table-responsive" >
              <a-table
                class="table border-0 custom-table comman-table datatable mb-0"
                :columns="lstColumns"
                :dataSource="handleFilterData"
                :rowKey="record => record.id_prueba_paciente"
                :pagination="{ pageSize: 15 }"
                :loading="bSpinner">
                <!-- :rowSelection="handleRowSelection" -->
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id_prueba_paciente'">
                      <router-link :to="{ name: 'edit-patient-test', params: {id: record.id_paciente, historyId: record.id_historia_clinica, appointmentId: record.id_consulta, testId: record.id_prueba_paciente}}">
                        #PXTEST-{{ record.id_consulta }}
                      </router-link>
                  </template>
                  <template v-if="column.key === 'id_historia_clinica'">
                      <router-link :to="{ name: 'patient-history-consultation', params: {id: record.id_paciente, parentId: record.id_historia_clinica }}">
                        #MEDHIS-{{ record.id_historia_clinica }}
                      </router-link>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <router-link class="dropdown-item" 
                            :to="{ name: 'edit-patient-test', params: {id: record.id_paciente, historyId: record.id_historia_clinica, appointmentId: record.id_consulta, testId: record.id_prueba_paciente}}">
                            <i class="fa-solid fa-pen-to-square m-r-5"></i> Editar
                          </router-link>
                          <!--a class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_patient">
                            <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                          </a-->
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
            <!-- /Table -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from '@/axios';
  import { format } from 'date-fns';

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
          const bValueStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "Prueba", strDataIndex: "id_prueba_paciente", strKey: "id_prueba_paciente" },
    { strTitle: "Historia", strDataIndex: "id_historia_clinica", strKey: "id_historia_clinica", bFilter: true },
    { strTitle: "Extremidad", strDataIndex: "strExtremidad", strKey: "strExtremidad", bFilter: true },
    { strTitle: "Cuerpo", strDataIndex: "strCuerpo", strKey: "strCuerpo", bFilter: true },
    { strTitle: "Prueba", strDataIndex: "strPrueba", strKey: "strPrueba", bFilter: true },
    { strTitle: "Fecha Creación", strDataIndex: "strFechaCreacion", strKey: "strFechaCreacion" },
    { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
      historyId: {
        type: String,
        default: null,
      },
      appointmentId: {
        type: String,
        default: "",
      },
    },
    emits: ["update:modelValue", "select"],
    data() {
      return {
        bSpinner: false,
        strSearchQuery: "",
        lstData,
        lstColumns,
        lstRowSelected,
        lstColumnsToCreate,
      };
    },
    computed: {
      handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
        const query = this.strSearchQuery.toLowerCase();

        return this.lstData.filter((objData) => {
          return this.lstColumnsToCreate.some((objColumn) => {
            if (!objColumn.bFilter) return false;
            const strValue = objData[objColumn.strDataIndex];
            const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
            return strValueSearch.includes(query);
          });
        });
      },
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    async created() {
      await this.getTest();
    },
    methods: {
      async getTest() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}/consultas/${this.appointmentId}/pruebas`);
          this.lstData = objResponse.data.map((objPrueba) => {
            const dtFechaCreacion = new Date(objPrueba.fecha_creacion);
            return {
              ...objPrueba,
              strExtremidad: objPrueba.extremidad?.nombre || '',
              strCuerpo: objPrueba.cuerpo_humano?.nombre || '',
              strPrueba: objPrueba.prueba_fisica?.nombre || '',
              strFechaCreacion: format(dtFechaCreacion, 'dd/MM/yyyy'),
            };
          });
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
        this.bSpinner = false;
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
      handleSearch() {
        // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      },
    }
  };
</script>
<template>
  <div class="combobox-container">
    <!-- Campo de entrada para escribir y filtrar las opciones -->
    <input
      type="text"
      class="form-control"
      :id="id"
      :name="name"
      v-model="searchQuery"
      :placeholder="placeholder"
      @focus="showDropdown = true"
      @blur="handleBlur"
      :disabled="disabled"
      :required="required"
    />
    
    <!-- Dropdown que muestra las opciones filtradas -->
    <div v-if="showDropdown" class="dropdown">
      <ul class="dropdown-list">
        <!-- Opciones filtradas -->
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          @mousedown="selectOption(option)"
          :class="{ 'focus': option.value === modelValue }"
          class="dropdown-item"
        >
          {{ option.label }}
        </li>
      </ul>
      
      <!-- Mensaje si no hay opciones -->
      <div v-if="filteredOptions.length === 0" class="no-results">
        No hay resultados
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComboBox',
  props: {
    modelValue: [String, Array], // El valor seleccionado
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Selecciona una opción'
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: '', // El texto que se escribe para filtrar
      showDropdown: false, // Controla la visibilidad del dropdown
      filteredOptions: [] // Opciones filtradas según el texto
    };
  },
  watch: {
    // Filtrar opciones cuando cambie la búsqueda o las opciones
    options: {
      handler() {
        this.filterOptions();
      },
      deep: true
    },
    searchQuery: 'filterOptions', // Re-filtrar cada vez que cambie la búsqueda
    modelValue: {
      handler(newValue) {
        this.searchQuery = newValue; // Sincronizar el texto de búsqueda con el valor seleccionado
      },
      immediate: true
    }
  },
  methods: {
    // Filtra las opciones basadas en lo que el usuario escribe
    filterOptions() {
      if (this.searchQuery) {
        this.filteredOptions = this.options.filter(option =>
          option.label.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      } else {
        this.filteredOptions = [...this.options]; // Si no hay búsqueda, mostrar todas las opciones
      }
    },
    // Seleccionar una opción
    selectOption(option) {
      this.$emit('update:modelValue', option.value); // Emitir el valor seleccionado
      this.searchQuery = option.label; // Actualizar la búsqueda con el texto de la opción seleccionada
      this.showDropdown = false; // Cerrar el dropdown
    },
    // Gestiona el evento blur para cerrar el dropdown
    handleBlur() {
      setTimeout(() => {
        this.showDropdown = false; // Cerrar el dropdown después de un breve retraso
      }, 200);
    }
  },
  mounted() {
    this.filteredOptions = [...this.options]; // Cargar las opciones cuando el componente se monta
  }
};
</script>

<style scoped>
.combobox-container {
  position: relative;
  width: 100%;
}

.form-control {
  box-shadow: none;
  padding: 10px 15px;
  font-size: 14px;
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}

.dropdown {
  position: absolute;
  width: 100%;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-top: none;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  margin-top: 2px;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  background-color: white; /* Fondo blanco por defecto */
  color: black; /* Color de texto negro por defecto */
}

.dropdown-item:hover {
  background-color: #2e37a4; /* Fondo al pasar el cursor */
  color: white; /* Texto blanco al pasar el cursor */
}

.selected {
  background-color: #2e37a4; /* Fondo al seleccionar */
  color: white; /* Texto blanco al seleccionar */
}

.no-results {
  padding: 8px;
  color: #888;
  font-size: 14px;
}
</style>

<template>
  <physiocloud-component-list
    :bLoading="bLoading"
    :bSelectRow="bSelectRow"
    :strTitleList="strTitleList"
    :strRoute="strRoute"
    :strPrimaryKey="strPrimaryKey"
    :objPagination="objPagination"
    :lstColumns="lstColumns"
    :lstData="lstData"
    @refresh="handleDataInit"
    @select="handleSelect"
  />
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstColumns = [
{ strTitle: "Articulo", strDataIndex: "strArticulo", strKey: "strArticulo", bFilter: true },
{ strTitle: "Lista Precio", strDataIndex: "strListaPrecio", strKey: "strListaPrecio", bFilter: true },
{ strTitle: "Moneda", strDataIndex: "strMoneda", strKey: "strMoneda", bFilter: true },
{ strTitle: "Precio", strDataIndex: "strPrecioo", strKey: "strPrecioo"},
{ strTitle: "Activo", strDataIndex: "strActivo", strKey: "strActivo"},
{ strTitle: "Fecha Creación", strDataIndex: "strFechaCreacion", strKey: "strFechaCreacion"},
{ strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end"},
];

export default {
data() {
  return {
    bLoading: false,
    bSelectRow: false,
    strTitleList: "Precio Articulo",
    strRoute: '/item-price',
    strPrimaryKey: 'id_precio_articulo',
    objPagination: { pageSize: 15 },
    lstColumns: lstColumns,
    lstData: [],
  };
},
async created() {
  this.handleDataInit();
},
methods: {
  async handleDataInit() {
    try{
      this.bLoading = true;
      const objResponse = await axios.get(`/precios-articulos`);
      this.lstData = objResponse.data.map((objResponse) => ({
        ...objResponse,
        strArticulo: objResponse.articulo?.nombre ?? '',
        strListaPrecio: objResponse.lista_precio?.nombre ?? '',
        strMoneda: objResponse.moneda?.nombre ?? '',
        strPrecioo: objResponse.precio ? parseFloat(objResponse.precio).toFixed(2) : '',
        strActivo: objResponse.activo ? "Activo" : "Inactivo",
        strFechaCreacion: format(new Date(objResponse.fecha_creacion), 'dd/MM/yyyy HH:mm:ss'),
      }));
    } catch (error) {
      console.error(error);
    } finally {
      this.bLoading = false;
    }
  },
  handleSelect(objRecord) {
    console.log(objRecord);
  },
}
};
</script>

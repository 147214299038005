<template>
  <div class="doctor-personals-grp">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Antecedentes Medicos</h5>
      </div>

      <div class="card-body">
        <b-tabs content-class="">
          <b-tab title="Patologias" active>
            <PatologyPatientProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstPatologiasPaciente" />
          </b-tab>
          <b-tab title="Habitos">
            <span>
              <HabitPatientProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstHabitosPaciente" />
            </span>
          </b-tab>
          <b-tab title="Alergias">
            <span>
              <AllergyPatientProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstAlergiasPaciente" />
            </span>
          </b-tab>
          <b-tab title="Actividad Fisica">
            <span>
              <SportActivityPatientProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstActvidadFisicaPaciente" />
            </span>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
  <!--div class="doctor-personals-grp">
    <div class="card">
      <div class="card-body">
        <div class="heading-detail">
          <h4 class="mb-3">Notas</h4>
          <p>
            Hello I am Smith Bruklin a Gynaecologist in Sanjivni Hospital Surat. I
            love to work with all my hospital staff and seniour doctors.
          </p>
        </div>
        <div class="about-me-list">
          <ul class="list-space">
            <li>
              <h4>Genero</h4>
              <span></span>
            </li>
            <li>
              <h4>Edad</h4>
              <span></span>
            </li>
            <li>
              <h4>Designation</h4>
              <span>Engineer</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div-->
</template>

<script>
  import axios from "@/axios";
  import AllergyPatientProfileList from "../allergy-patient/AllergyPatientProfileList.vue";
  import HabitPatientProfileList from "../habit-patient/HabitPatientProfileList.vue";
  import PatologyPatientProfileList from "../patology-patient/PatologyPatientProfileList.vue";
  import SportActivityPatientProfileList from "../sport-activity-patient/SportActivityPatientProfileList.vue";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        bLoading: false,
        lstPatologiasPaciente: [],
        lstAlergiasPaciente: [],
        lstHabitosPaciente: [],
        lstActvidadFisicaPaciente: [],
      };
    },
    components: {
      PatologyPatientProfileList,
      HabitPatientProfileList,
      AllergyPatientProfileList,
      SportActivityPatientProfileList
    },
    created(){
      this.bLoading = true;
      this.loadData();
    },
    methods: {
      async loadData() {
        this.bLoading = true;
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}/informacion-adicional`);
          const objData = objResponse.data;
          
          this.lstAlergiasPaciente = objData.alergias.map(objAlergia => ({
            ...objAlergia,
            strNombre: objAlergia.alergia?.nombre ?? '',
          }));
          this.lstHabitosPaciente = objData.habitos.map(objHabito => ({
            ...objHabito,
            strNombre: objHabito.habito?.nombre ?? '',
          }));
          this.lstPatologiasPaciente = objData.patologias.map(objPatologia => ({
            ...objPatologia,
            strNombre: objPatologia.patologia?.nombre ?? '',
          }));
          this.lstActvidadFisicaPaciente = objData.actividades.map(objActividadFisica => ({
            ...objActividadFisica,
            strNombre: objActividadFisica.actividadFisica?.nombre ?? '',
          }));
        } catch (error) {
          console.error(`Error al obtener historial medico: `, error);
        }
        this.bLoading = false;
      },
    },
    
  };
</script>
<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb title="Precio Producto" text="Formulario de Precio Producto" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";

const lstActivo = [
  { id: "1", text: "Activo" },
  { id: "0", text: "Inactivo" },
];

const lstFields = [
  { name: "id_articulo", label: "Articulo", type: 'select', options: lstActivo, required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_lista_precio", label: "Lista Precio", type: 'select', options: lstActivo, required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_moneda", label: "Moneda", type: 'select', options: lstActivo, required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "precio", label: "Precio", type: 'text', required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "activo", label: "Activo", type: 'select', options: lstActivo, strClass: "col-12 col-md-6 col-xl-4"},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strRecordId: this.recordId,
      strPath: "/settings/pos",
      strFormTitle: "Formulario Precio Producto",
      strApiRoute: "/precios-articulos",
      lstActivo: lstActivo,
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleInitForm();
    this.handleCatalogos();
    await this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        id_articulo: "",
        id_lista_precio: "",
        id_moneda: "",
        precio: "",
        activo: "1",
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`${this.strApiRoute}/${this.strRecordId}`);
        this.objForm = objResponse.data;
        this.objForm.id_articulo = String(this.objForm.id_articulo);
        this.objForm.id_lista_precio = String(this.objForm.id_lista_precio);
        this.objForm.id_moneda = String(this.objForm.id_moneda);
        this.objForm.activo = this.objForm.activo ? "1" : "0";
      }
    },
    async handleCatalogos(){
      try{
        const objResponse = await axios.get("/precios-articulos/catalogos/");
        this.lstFields.find(field => field.name === "id_articulo").options = objResponse.data.articulos.map(objArticulo => ({
          id: String(objArticulo.id_articulo),
          text: String(objArticulo.nombre),
        }));
        this.lstFields.find(field => field.name === "id_lista_precio").options = objResponse.data.lista_precios.map(objListaPrecio => ({
          id: String(objListaPrecio.id_lista_precio),
          text: String(objListaPrecio.nombre),
        }));
        this.lstFields.find(field => field.name === "id_moneda").options = objResponse.data.monedas.map(objMoneda => ({
          id: String(objMoneda.id_moneda),
          text: String(objMoneda.nombre),
        }));
      }catch(error){
        console.error(error);
      }
    },
    handleSubmit(objForm){
      this.$router.go(-1);
      this.handleInitForm();
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>

<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb title="Tickets" text="Formulario de Ticket" path="/ticket/list" />
      <div class="card-body about-info">
        <span>
          <div class="dropdown dropdown-action">
            <a
              href="javascript:;"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-end">
              <a
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#new_ticket_form"
                class="dropdown-item">
                Editar
              </a>
            </div>
          </div>
        </span>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card invoices-add-card">
            <div class="card-body">
              <form @submit.prevent="" class="invoices-form">
                <div class="invoices-main-form">
                  <div class="row">
                    <div class="col-xl-12 col-md-6 col-sm-12 col-12">
                      <h4 class="invoice-details-title">Detalle Ticket</h4>
                      <div class="invoice-details-box">
                        <div class="invoice-inner-head">
                          <span>Ticket No: {{ strTicket }}</span>
                        </div>
                        <div class="invoice-inner-footer">
                          <div class="row align-items-center">
                            <div class="col-lg-2 col-md-6">
                              <div class="invoice-inner-date">
                                <span class="d-flex">
                                  Fecha
                                  <input class="form-control" type="date" v-model="dtFecha" 
                                    placeholder="dtFecha" required="false" readonly="true"/>
                                </span>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="invoice-inner-date ">
                                <span class="w-100" v-if="objForm.strPaciente">
                                  {{ objForm.strPaciente }}
                                </span>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <div class="invoice-inner-date">
                                <span class="d-flex" v-if="objForm.strFormaPago">
                                  {{ objForm.strFormaPago }}
                                </span>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <div class="invoice-inner-date">
                                <span class="d-flex" v-if="objForm.strEstatus">
                                  {{ objForm.strEstatus }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ItemTicketForm :objData="objForm" @changeItem="handleChangeItem($event)"/>
                <div class="row">
                  <TicketInformationForm :objData="objForm" @change="handleChangeInfo($event)"/>

                  <div class="col-lg-5 col-md-6">
                    <div class="invoice-total-card">
                      <h4 class="invoice-total-title">Resumen</h4>
                      <div class="invoice-total-box">
                        <div class="invoice-total-footer">
                          <p>Total articulos <span> {{ objForm.iTotalArticulos }} </span></p>
                          <p>Subtotal <span>$ {{ parseFloat(objForm.subtotal).toFixed(2) }}</span></p>
                          <p>Descuento <span>$ -{{ parseFloat(objForm.descuento).toFixed(2) }}</span></p>
                          <h4>Total a Pagar <span>$ {{ parseFloat(objForm.total).toFixed(2) }}</span></h4>
                        </div>
                      </div>
                    </div>
                    <div class="upload-sign">
                      <div class="form-group float-end mb-0">
                        <b-button variant="primary" @click="handleSubmit">Guardar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TicketFormModal :isEdit="true" :recordId="strRecordId" @submit="handleRefresh"/>
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';
import ItemTicketForm from "@/views/pages/item-ticket/ItemTicketForm.vue";
import TicketInformationForm from "@/views/pages/ticket/TicketInformationForm.vue";
import TicketFormModal from "@/views/pages/ticket/TicketFormModal.vue";

export default {
  props: {
    isEdit: { type: Boolean, default: false },
    recordId: { type: String, default: null },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strTicket: "",
      strRecordId: this.recordId,
      strPath: "/ticket/list",
      fltTotal: 0.00,
      lstCarrito: [],
      lstItemsDelete: [],
      dtFecha: format(new Date(), "yyyy-MM-dd"),
      objForm: {},
    };
  },
  components: {
    ItemTicketForm,
    TicketInformationForm,
    TicketFormModal,
  },
  emits: ['submit', 'cancel', 'select'],
  async created() {
    this.handleInitForm();
    this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        id_ticket: "",
        id_paciente: "",
        id_lista_precio: "1",
        fecha: format(new Date(), "yyyy-MM-dd"),
        id_forma_pago: "1",
        id_estatus_ticket: "1",
        subtotal: 0.00,
        descuento: 0.00,
        total: 0.00,
        notas: "",
        iTotalArticulos: 0,
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`/tickets/${this.strRecordId}`);
        this.objForm = objResponse.data;
        this.dtFecha = format(new Date(this.objForm.fecha), "yyyy-MM-dd");
        this.objForm.strPaciente = `${this.objForm.paciente?.nombre ?? ''} ${this.objForm.paciente?.apellido_paterno ?? ''} ${this.objForm.paciente?.apellido_materno ?? ''}`;
        this.objForm.strFormaPago = `${this.objForm.forma_pago?.nombre ?? ''}`;
        this.objForm.strListaPrecio = `${this.objForm.lista_precio?.nombre ?? ''}`;
        this.objForm.strEstatus = `${this.objForm.estatus_ticket?.nombre ?? ''}`;
      }
      this.strTicket = this.isEdit && this.strRecordId ? 'TK-#' + this.strRecordId : 'Nuevo Ticket';
    },
    handleChangeItem(objCarrito){
      const fltSubtotal = objCarrito.lstCarrito.reduce((acc, objItem) => acc + (objItem.cantidad * objItem.precio) , 0);
      const fltDescuento = objCarrito.lstCarrito.reduce((acc, objItem) => acc + objItem.descuento, 0);
      const fltTotal = objCarrito.lstCarrito.reduce((acc, objItem) => acc + objItem.total, 0);
      const iTotalArticulos = objCarrito.lstCarrito.reduce((acc, objItem) => acc + objItem.cantidad, 0);
      this.lstCarrito = objCarrito.lstCarrito;
      this.lstItemsDelete = objCarrito.lstItemsDelete;
      this.objForm.iTotalArticulos = iTotalArticulos;
      this.objForm.descuento = fltDescuento ?? 0.00;
      this.objForm.subtotal = fltSubtotal ?? 0.00;
      this.objForm.total = fltTotal ?? 0.00;
    },
    handleChangeInfo(objForm) {
      this.objForm.notas = objForm.notas;
    },
    handleRefresh(objForm){
      this.handleDataInit();
    },
    async handleSubmit() {
      try{
        
        if(this.lstItemsDelete) await this.handleDeleteItems();
        await this.handleSubmitItems();
        await axios.put(`/tickets/${this.objForm.id_ticket}`, this.objForm);

        this.$toast.show('Ticket guardado correctamente', { type: 'success' });
      }catch(err){
        console.error(err);
        this.$toast.show('Error al guardar el ticket', { type: 'error' });
      }
    },
    async handleSubmitItems(){
      try{
        if(this.lstCarrito.filter(item => item.id_articulo) == 0) return;
        const lstItems = this.lstCarrito.reduce((acc, objItem) => {
          if (acc[objItem.id_articulo]) {
            acc[objItem.id_articulo].id_ticket = this.objForm.id_ticket;
            acc[objItem.id_articulo].cantidad += objItem.cantidad;
            acc[objItem.id_articulo].total += objItem.total;
            acc[objItem.id_articulo].descuento += objItem.descuento;
          } else {
            acc[objItem.id_articulo] = { 
              ...objItem,
              id_ticket: this.objForm.id_ticket,
            };
          }
          return acc;
        }, {});

         await Promise.all(Object.values(lstItems).map(async objItem => {
          if(objItem.id_articulo_ticket) return await axios.put(`/articulos-tickets/${objItem.id_articulo_ticket}`, objItem);
          if(!objItem.id_articulo_ticket) return await axios.post('/articulos-tickets', objItem);
        }));
        this.handleRefresh();
      }catch(err){
        console.error(err);
        this.$toast.show('Error al guardar el ticket', { type: 'error' });
      }
    },
    async handleDeleteItems(){
      try{
        if(this.lstItemsDelete.filter(item => item.id_articulo) == 0) return;
         await Promise.all(this.lstItemsDelete.map(async objItem => {
          return await axios.delete(`/articulos-tickets/${objItem.id_articulo_ticket}`);
        }));
        this.lstItemsDelete = [];
      }catch(err){
        console.error(err);
        this.$toast.show('Error al guardar el ticket', { type: 'error' });
      }
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
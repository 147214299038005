<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />

      <TicketStatusList />
    </div>
  </div>
<delete></delete>
</template>
<script>
import TicketStatusList from "./TicketStatusList.vue";

export default {
  data() {
    return {
      strTitle: "Estatus Tickets",
      strSubutitle: "Lista de Estatus Tickets",
      strPath: "/general/pos",
    };
  },
  components: {
    TicketStatusList,
  },
  created() {
    
  },
  methods: {

  }
};
</script>

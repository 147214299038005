<template>
    <div class="col-lg-7 col-md-6">
      <div class="invoice-fields">
        <h4 class="field-title">Información adicional</h4>
        <!--div class="field-box">
          <p>Payment Details</p>
          <a
            class="btn btn-primary"
            href="javascript:void(0);"
            data-bs-toggle="modal"
            data-bs-target="#bank_details"
            ><i class="fas fa-plus-circle me-2"></i>Add Bank Details</a
          >
        </div-->
      </div>
      <div class="invoice-faq">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <!--div class="faq-tab">
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingTwo">
                <p class="panel-title">
                  <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion"
                    href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <i class="fas fa-plus-circle me-1"></i> Add Terms & Conditions
                  </a>
                </p>
              </div>
              <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                aria-labelledby="headingTwo" data-bs-parent="#accordion">
                <div class="panel-body">
                  <textarea class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div-->
          <div class="faq-tab">
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingThree">
                <p class="panel-title">
                  <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion"
                    href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <i class="fas fa-plus-circle me-1"></i> Notas
                  </a>
                </p>
              </div>
              <div id="collapseThree" class="panel-collapse collapse show" role="tabpanel" 
                aria-labelledby="headingThree" data-bs-parent="#accordion">
                <div class="panel-body">
                  <textarea class="form-control" v-model="objForm.notas" @input="handleChange"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    objData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      objForm: {},
    };
  },
  emits: ["change"],
  watch: {
    objData: function (val) {
      this.objForm = val;
      this.objForm.notas = val.notas ?? "";
    },
  },
  methods: {
    handleChange() {
      this.$emit("change", this.objForm);
    },
  },
};
</script>

<template>
  <!-- Page Wrapper -->
    <div class="row">
      <div class="col-sm-12">
        <div class="card card-table show-entire">
          <div class="card-body">
            <!-- Table Header -->
            <div class="page-table-header mb-2">
              <div class="row align-items-center">
                <div class="col">
                  <div class="doctor-table-blk">
                    <h3>Buscar paciente</h3>
                    <div class="doctor-search-blk">
                      <div class="add-group">
                        <b-button
                          @click="handleAdd"
                          variant="btn btn-primary add-pluss ms-2"
                          ><img src="@/assets/img/icons/plus.svg" alt=""/>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Table Header -->
            <div class="staff-search-table">
              <form @submit.prevent="handleSearch">
                <div class="row">
                  <div class="col-12 col-md-3 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Nombre del Paciente </label>
                      <input 
                        class="form-control" 
                        type="text" 
                        placeholder="" 
                        v-model="objSearchQuery.nombre_paciente"
                        @input="handleInput"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Apellido Paterno </label>
                      <input 
                        class="form-control" 
                        type="text" 
                        placeholder="" 
                        v-model="objSearchQuery.apellido_paterno"
                        @input="handleInput"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-2 col-xl-2">
                    <div class="input-block local-forms">
                      <label>Apellido Materno </label>
                      <input 
                        class="form-control" 
                        type="text" 
                        placeholder="" 
                        v-model="objSearchQuery.apellido_materno"
                        @input="handleInput"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-2 col-xl-2">
                    <div class="doctor-submit">
                      <b-button @click="handleSearch" :disabled="bDisabledSearch" variant="btn btn-primary submit-list-form me-2">Buscar</b-button>
                    </div>
                  </div>
                  <div class="col-12 col-md-2 col-xl-2">
                    <div class="doctor-submit">
                      <b-button @click="handleClean" variant="btn btn-primary cancel-form submit-list-form me-3">Limpiar</b-button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            
            <div class="table-responsive" >
              <a-table
                class="table border-0 custom-table comman-table datatable mb-0"
                :columns="lstColumns"
                :dataSource="lstData"
                :rowSelection="handleRowSelection"
                :rowKey="record => record.id_paciente"
                :loading="bSpinner"
                :pageSize="20"
                >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id_paciente'">
                    <div class="profile-image">
                      <router-link to="/profile">
                        <img
                          width="28"
                          height="28"
                          :src="require(`@/assets/img/user.jpg`)"
                          class="rounded-circle m-r-5"
                          alt=""/>
                        PX-#{{ record.id_paciente }}
                      </router-link>
                    </div>
                  </template>
                  <template v-if="column.key === 'nombre_completo'">
                    <div class="profile-image">
                      <router-link :to="{ name: 'patient-profile', params: { id: record.id_paciente } }">
                        {{ record.nombre_completo }}
                      </router-link>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'telefono_celular'">
                    <div><a href="javascript:;">{{ record.telefono_celular }}</a></div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div>
                      <b-button class="btn btn-sm btn-primary" @click="handlePaciente(record)">
                        Seleccionar
                      </b-button>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- /Page Wrapper -->
</template>
<script>
  import axios from "@/axios";
  import { format } from 'date-fns';
  import AppointmentPatientForm from "./AppointmentPatientForm.vue";

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
          const bValueStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "No. Paciente", strDataIndex: "id_paciente", strKey: "id_paciente" },
    { strTitle: "Nombre", strDataIndex: "nombre_completo", strKey: "nombre_completo" },
    { strTitle: "Genero", strDataIndex: "strGenero", strKey: "strGenero" },
    { strTitle: "Celular", strDataIndex: "telefono_celular", strKey: "telefono_celular" },
    { strTitle: "Correo", strDataIndex: "correo_electronico" },
    { strTitle: "Fecha Nacimiento", strDataIndex: "strFechaNacimiento", strKey: "strFechaNacimiento" },
    { strTitle: "", strDataIndex: "action", bSorter: true, strClass: "text-end" },
  ];

  const objSearchQuery = {
    nombre_paciente: "",
    apellido_paterno: "",
    apellido_materno: "",
  };

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {
    emits: ['onFormPaciente', 'paciente'],
    data() {
      return {
        bSpinner: false,
        bDisabledSearch: true,
        bFormPaciente: false,
        objSearchQuery,
        lstData,
        lstColumns,
        lstRowSelected,
      };
    },
    components: {
      AppointmentPatientForm,
    },
    computed: {
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    created() {},
    methods: {
      async getPacientes() {
        this.bSpinner = true;
        this.lstData = [];
        try {
          const objResponse = await axios.get('/pacientes/search?nombre=' + encodeURIComponent(this.objSearchQuery.nombre_paciente) + 
            '&apellido_paterno=' + encodeURIComponent(this.objSearchQuery.apellido_paterno) + 
            '&apellido_materno=' + encodeURIComponent(this.objSearchQuery.apellido_materno));
            
          this.lstData = objResponse.data.map((objPaciente) => ({
            ...objPaciente,
            nombre_completo: `${objPaciente.nombre ?? ''} ${objPaciente.apellido_paterno ?? ''} ${objPaciente.apellido_materno ?? ''}`,
            strGenero : objPaciente.genero?.nombre ?? '',
            strSucursal: objPaciente.sucursal?.nombre ?? '',
            strFechaNacimiento: format(new Date(objPaciente.fecha_nacimiento), 'dd/MM/yyyy'),
          }));
        } catch (error) {
          console.error('Error fetching data:', error.message);
          console.error('Error fetching data:', error.response.data);
        }
        this.bSpinner = false;
      },
      handleAdd(){
        this.bFormPaciente = true;
        this.$emit('onFormPaciente', true);
      },
      handleSearch() {
        if(!this.bDisabledSearch) this.getPacientes();
      },
      handleClean() {
        this.objSearchQuery = {
          nombre_paciente: "",
          apellido_paterno: "",
          apellido_materno: "",
        };
      },
      handlePaciente(record) {
        this.$emit('paciente', record);
      },
      handleInput() {
        this.bDisabledSearch = Object.values(this.objSearchQuery).every((strValue) => strValue.length < 3);
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
    }
  };
</script>

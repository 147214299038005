<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <PatologyPatientForm :id="id"/>
    </div>
  </div>
<delete></delete>
</template>
<script>
import PatologyPatientForm from "./PatologyPatientForm.vue";

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      strTitle: `Paciente #${this.id}`,
      strSubutitle: "Formulario de Patologias",
      strPath: `/patient/${this.id}/profile`,
    };
  },
  components: {
    PatologyPatientForm,
  },
  async created() {

  },
  methods: {

  }
};
</script>

<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="about-info">
          <h4>
            Perfil del Paciente
            <span>
              <div class="dropdown dropdown-action">
                <a
                  href="javascript:;"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item" 
                      :to="{ name: 'edit-patient', params: {id: this.id}}">Editar</router-link>
                </div>
              </div>
            </span>
          </h4>
        </div>
        <div class="card-box profile-header">
          <div class="row">
            <div class="col-md-12">
              <div class="profile-view">
                <div class="profile-img-wrap">
                  <div class="profile-img">
                    <a href="javascript:;"
                      ><img class="avatar" src="@/assets/img/doctor-03.jpg" alt=""
                    /></a>
                  </div>
                </div>
                <div class="profile-basic">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="profile-info-left">
                        <h3 class="user-name m-t-0 mb-0">{{ objPaciente.strNombreCompleto }}</h3>
                        <small class="text-muted">{{ objPaciente.strSucursal }}</small>
                        <div class="staff-id">Paciente : PX-#{{ id }}</div>
                        <!--div class="staff-msg">
                          <router-link to="/chat" class="btn btn-primary">Consultar</router-link
                          >
                        </div-->
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="personal-info">
                        <li>
                          <span class="title">Telefono:</span>
                          <span class="text"><a href="">{{ objPaciente.telefono_celular }}</a></span>
                        </li>
                        <li>
                          <span class="title">Correo:</span>
                          <span class="text"
                            ><a href="">{{ objPaciente.correo_electronico }}</a></span
                          >
                        </li>
                        <li>
                          <span class="title">Edad:</span>
                          <span class="text">{{ objPaciente.iEdad }}</span>
                        </li>
                        <li>
                          <span class="title">Genero:</span>
                          <span class="text">{{ objPaciente.strGenero }}</span>
                        </li>
                        <li>
                          <span class="title">Locación:</span>
                          <span class="text">{{ objPaciente.strLocacion }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "../../../../axios.js";
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        objPaciente: {},
      };
    },
    async mounted(){
      await this.getPaciente();
    },
    methods: {
      async getData(strVariable, strFieldName, strEndpoint) {
        try {
          const objResponse = await axios.get(strEndpoint);
          this[strVariable] = objResponse.data.reduce((mapObject, objObject) => {
            mapObject[objObject[strFieldName]] = objObject.nombre;
            return mapObject;
          }, {});
        } catch (error) {
          console.error(`Error al obtener datos de ${strEndpoint}: `, error);
        }
      },
      async getPaciente() {
        try {
          let strCiudad = '', strLocalidad = '', strLocacion;
          const objResponse = await axios.get(`/pacientes/${this.id}`);
          this.objPaciente = objResponse.data;
          this.objPaciente.strNombreCompleto = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
          this.objPaciente.fecha_nacimiento = new Date(this.objPaciente.fecha_nacimiento).toISOString().split('T')[0];
          this.objPaciente.iEdad = `${new Date().getFullYear() - new Date(this.objPaciente.fecha_nacimiento).getFullYear()} años`;
          this.objPaciente.strGenero = this.objPaciente.genero?.nombre ?? '';
          this.objPaciente.strSucursal = this.objPaciente.sucursal?.nombre ?? '';

          strCiudad = objResponse.data.ciudad?.nombre ?? '';
          strLocalidad = objResponse.data.localidad?.nombre ?? '';

          if(strCiudad.length < 1 || strLocalidad.length < 1) strLocacion = 'No disponible';
          else strLocacion = `${this.objPaciente.ciudad?.nombre ?? ''}, ${this.objPaciente.localidad?.nombre ?? ''}`;
          
          this.objPaciente.strLocacion = strLocacion
      
        } catch (error) {
          console.error(`Error al obtener paciente: `, error);
        }
      },
    },
  };
</script>

<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalles de Cita</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>
              
              <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                <div :class="field.class">
                  <div class="input-block local-forms">
                    <template v-if="field.type === 'text' || field.type == 'date' || field.type === 'time'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <input
                        class="form-control"
                        :type="field.type"
                        v-model="objForm[field.name]"
                        @change="handleChangeInput(field.name, $event)"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :disabled="field.disabled"
                      />
                    </template>
                    <!--template v-if="field.type === 'time'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <a-time-picker
                        class="form-control"
                        placeholder="11:00 AM"
                        :use12-hours="true"
                        format="h:mm A"
                        type="text"
                        :required="field.required"
                        v-model="objForm[field.name]" 
                        :value="dtHoraInicio"
                        @change="handleTime"
                        @ok="handleTime" />
                    </template-->
                    <template v-if="field.type === 'textarea'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <b-form-textarea
                        id="textarea"
                        v-model="objForm[field.name]"
                        rows="5"
                        max-rows="6"
                        placeholder="Ingresa el texto aqui">
                      </b-form-textarea>
                    </template>
                    <template v-if="field.type === 'select'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleSelectField(field.name, $event)"
                      :disabled="field.disabled"
                      :required="field.required"
                      />
                    </template>
                  </div>
                </div>
              </template>
              <div class="col-12">
                <div class="doctor-submit text-end">
                  <b-button type="submit" variant="btn btn-primary submit-form me-2" v-if="bGuardar == false" >Guardar Registro</b-button>
                  <b-button variant="btn btn-primary submit-form me-2" v-if="bGuardar" >
                    <b-spinner class="spinner-border-sm me-1"></b-spinner>
                    Guardando...
                  </b-button>
                  <b-button @click="handleCancel" variant="btn btn-primary cancel-form">Cancelar</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import dayjs from 'dayjs';
import locale from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Spinner from "@/components/Spinner.vue";
import { addHours, format } from "date-fns";

dayjs.extend(locale);
//dayjs.locale('es');
dayjs.extend(customParseFormat);

const lstFields = [
{ name: "fecha_inicio", label: "Fecha Inicio", type: 'date', required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "hora_inicio", label: "Hora Inicio", type: 'time', required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "hora_fin", label: "Hora Fin", type: 'time', required: false, class: "col-12 col-md-6 col-xl-4"},
{ name: "id_estatus_agenda", label: "Estatus", type: 'select', options: [], required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "id_categoria_agenda", label: "Categoria", type: 'select', options: [], required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "notas", label: "Notas", type: 'textarea'},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      objForm: this.handleInitForm(),
      lstFields,
      lstPacientes: [],
      dtHoraInicio: '',
    };
  },
  emits: ['submit', 'cancel', 'select'],
  components: {
    Spinner,
  },
  async created() {
    if(!this.isEdit) this.objForm = this.handleInitForm();

    await this.getData();
    if(this.isEdit) await this.handleDataForm();
  },
  methods: {
    handleInitForm(){
      return {
        fecha_inicio: "",
        hora_inicio: "",
        hora_fin: "",
        id_categoria_agenda: "",
        id_estatus_agenda: "",
        notas: "",
      };
    },
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/catalogos/citas`);
        let objDataCategoria = objResponse.data.categoriasAgenda;
        let objDataEstatus = objResponse.data.estatusAgenda;

        this.lstFields.find(objField => objField.name === "id_categoria_agenda").options =
          objDataCategoria.map(objItem => ({ id: String(objItem.id_categoria_agenda), text: String(objItem.nombre) }));

        this.lstFields.find(objField => objField.name === "id_estatus_agenda").options =
          objDataEstatus.map(objItem => ({ id: String(objItem.id_estatus_agenda), text: String(objItem.nombre) }));
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    async handleDataForm() {
      this.bSpinner = true;
      try {
          const objResponse = await axios.get(`citas/${this.recordId}`);
          this.objForm = objResponse.data;
          this.objForm.fecha_inicio = objResponse.data.fecha_inicio.split('T')[0];

          const dtHoraInicio = new Date(objResponse.data.hora_inicio);
          const dtHoraFin = objResponse.data.hora_fin ? new Date(objResponse.data.hora_fin) : addHours(dtHoraInicio, 1);

          this.objForm.hora_inicio = format(dtHoraInicio, 'HH:mm');
          this.objForm.hora_fin = format(dtHoraFin, 'HH:mm');
          
          
          this.dtHoraInicio = dtHoraInicio;
          this.dtHoraFin = dtHoraFin;
          this.objForm.id_categoria_agenda = String(objResponse.data.id_categoria_agenda);
          this.objForm.id_estatus_agenda = String(objResponse.data.id_estatus_agenda);
      } catch (error) {
        console.error(`Error al obtener datos del formulario: `, error);
      }
      this.bSpinner = false;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }
      
      this.objForm[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    async submitForm() {
      if(!this.validateForm()) return;
      if(!this.objForm.fecha_inicio || !this.objForm.hora_inicio) return;
      this.bGuardar = true;
      this.objForm.id_paciente = this.id;
      this.objForm.hora_inicio = dayjs(this.dtHoraInicio).toDate();
      this.objForm.hora_fin = dayjs(this.dtHoraFin).toDate();
      try{
        if(this.isEdit) await axios.put(`/agendas/${this.recordId}`, this.objForm);
        if(!this.isEdit) await axios.post("/agendas", this.objForm);
        this.toast("success", true, "Cita registrada correctamente");
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar cita:', error);
        this.toast("error", true, "Ocurrio un error al registrar la cita, intente nuevamente.");
      }
      this.bGuardar = false;
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleChangeInput(strField, event) {
      if(strField == 'hora_inicio' && event.target.value) {
        
        const dtHoraInicio = new Date(`${this.objForm.fecha_inicio}T${event.target.value}`);
        const dtHoraFin = addHours(dtHoraInicio, 1);
         
        this.objForm.hora_fin = format(addHours(dtHoraInicio, 1), 'HH:mm');
        this.dtHoraInicio = dtHoraInicio;
        this.dtHoraFin = dtHoraFin;
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />

      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false" >
                    <div :class="field.strClass">
                      <div class="input-block local-forms">
                        <template v-if="field.type === 'password'">
                          <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                          <input
                            class="form-control"
                            :type="field.type"
                            v-model="objForm[field.name]"
                            :placeholder="field.placeholder"
                            :required="field.required"
                            :disabled="field.disabled"
                            minlength="8"
                            maxlength="20"
                          />
                        </template>
                      </div>
                    </div>
                  </template>
                  <div class="col-12">
                    <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                    <b-button variant="primary me-1" v-if="bGuardar" >
                      <b-spinner class="spinner-border-sm me-1"></b-spinner>
                      Guardando...
                    </b-button>
                    <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";

const lstFields = [
  { name: "contrasena_anterior", label: "Contraseña anterior", type: 'password', required: true, strClass: "col-12 col-md-12 col-xl-12"},
  { name: "nueva_contrasena", label: "Nueva contraseña", type: 'password', required: true, strClass: "col-12 col-md-6 col-xl-6"},
  { name: "confirmar_contrasena", label: "Confirmar contraseña", type: 'password', required: true, strClass: "col-12 col-md-6 col-xl-6"}
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      strTitle: "Usuario",
      strSubutitle: "Cambiar contraseña",
      strPath: "/user/password/edit",
      lstFields,
      objForm: {},
    };
  },
  async created() {
    
  },
  methods: {
    async handleSubmit() {
      if (!this.validateForm()) return;
      if (!this.validateNewPassword()) return;

      try {
        const objResponse = await axios.post('/usuario/change-password', this.objForm);
        this.toast('success', true, objResponse.data.message);
        this.$router.go(-1);
      } catch (error) {
        console.error('Error en la solicitud:', error);
        const strError = error.response?.data?.message || 'Hubo un problema al procesar tu solicitud';
        this.toast('error', true, strError);  // Muestra el mensaje de error en el toast
      }
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    validateNewPassword() {
      if(this.objForm.nueva_contrasena !== this.objForm.confirmar_contrasena) {
        this.toast('error', true, 'Las contraseñas no coinciden');
        this.objForm = {};
        return false;
      }
      return true;
    },
    handleCancel(){
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },

  }
};
</script>

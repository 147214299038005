<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-heading">
                <h4>Información Geografica</h4>
              </div>
            </div>
            <template v-for="(field, index) in lstFields" :key="index">
              <div :class="field.strClass">
                <div class="input-block local-forms">
                  <template v-if="field.type !== 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                      class="form-control"
                      :type="field.type"
                      v-model="objForm[field.name]"
                      :placeholder="field.placeholder"
                      :required="field.required"
                      :disabled="field.disabled"
                      @change="handleInputField(field.name, $event, 'input')"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleInputField(field.name, $event, 'select')"
                      :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";

const lstFields = [
  { name: "id_codigo_postal", label: "Código Postal", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "id_pais", label: "País", type: "select", required: false, disabled: true, options: [], strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "id_localidad", label: "Localidad", type: "select", required: false, disabled: true, options: [], strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "id_ciudad", label: "Ciudad", type: "select", required: false, disabled: true, options: [], strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "id_colonia", label: "Colonia", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "calle", label: "Calle", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "numero_interior", label: "Número Interior", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
  { name: "numero_exterior", label: "Número Exterior", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bGeografica: true },
];

export default {
  props: {
    objData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lstCatalagos: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      lstFields,
      objForm: {},
    };
  },
  emits: ['paciente'],
  watch: {
    objData: {
      handler: function (value) {
        if(value.id_codigo_postal){
          let strCodigoPostal = JSON.parse(value.id_codigo_postal);
          if(strCodigoPostal) this.getRelatedDataByPostalCode(strCodigoPostal);
        }
        this.objForm = value;
      },
      immediate: true,
    },
    lstCatalagos: {
      handler: function (value) {
        if(value.codigosPostales) this.updateFieldOptions('id_codigo_postal', 'codigo_postal', value.codigosPostales);
      },
      immediate: true,
    },
  },
  async created() {
    this.objForm = this.objData;
    await this.getCodigosPostales();
    this.handleColoniaField();
  },
  methods: {
    async getCodigosPostales() {
      try {
        const objCodigoPostal = await axios.get('/codigos-postales');
        this.updateFieldOptions('id_codigo_postal', 'codigo_postal', objCodigoPostal.data);
      } catch (error) {
        console.error('Error al obtener codigos postales:', error);
      }
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      let lstSelected = ['id_pais', 'id_localidad', 'id_ciudad'];
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];

      if(lstSelected.includes(strFieldIndex)) this.objForm[strFieldIndex] = field.options[0].id;
    },
    async getRelatedDataByPostalCode(strCodigoPostal) {
      if (!strCodigoPostal || strCodigoPostal.length < 1) return;
      try {
        const objCodigoPostal = await axios.get(`/codigos-postales/${strCodigoPostal}/colonias`);  
        this.updateFieldOptions('id_pais', 'nombre', objCodigoPostal.data.pais);
        this.updateFieldOptions('id_localidad', 'nombre', objCodigoPostal.data.localidad);
        this.updateFieldOptions('id_ciudad', 'nombre', objCodigoPostal.data.ciudad);
        this.updateFieldOptions('id_colonia', 'nombre', objCodigoPostal.data.colonias);
        this.$emit('paciente',  this.objForm);
      } catch (error) {
        console.error('Error al obtener datos por código postal:', error);
      }
    },
    handleInputField(strFieldName, event, strType) {
      const strValue = strType === 'input' ? event.target.value : event.id;
      this.objForm[strFieldName] = strValue; 
      if(strFieldName !== "id_codigo_postal") this.$emit('paciente',  this.objForm);
      if(strFieldName === "id_codigo_postal"){
        if(strValue.length > 0) this.getRelatedDataByPostalCode(strValue);
        this.handleColoniaField();
      }
    },
    handleColoniaField(){
      let bDisabled = this.objForm.id_codigo_postal ? false : true;
      this.lstFields.forEach((field) => {
        if(field.name === 'id_colonia') field.disabled = bDisabled;
      });
    }
  },
};
</script>

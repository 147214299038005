<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración Punto de Venta</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Formas de Pago">
                  <span>
                    <PaymentMethodList />
                  </span>
                </b-tab>
                <b-tab title="Monedas">
                  <span>
                    <CurrencyList />
                  </span>
                </b-tab>
                <b-tab title="Categoria Articulo">
                  <span>
                    <ItemCategoryList />
                  </span>
                </b-tab>
                <b-tab title="Estatus Ticket">
                  <span>
                    <TicketStatusList />
                  </span>
                </b-tab>
                <b-tab title="Lista de Precios">
                  <span>
                    <PriceBookList />
                  </span>
                </b-tab>
                <b-tab title="Articulos">
                  <span>
                    <ItemList />
                  </span>
                </b-tab>
                <b-tab title="Precio Articulos">
                  <span>
                    <ItemPriceList />
                  </span>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CurrencyList from '../currency/CurrencyList.vue';
import PaymentMethodList from '../payment-method/PaymentMethodList.vue';
import ItemCategoryList from '../item-category/ItemCategoryList.vue';
import TicketStatusList from '../ticket-status/TicketStatusList.vue';
import PriceBookList from '../pricebook/PriceBookList.vue';
import ItemList from '../item/ItemList.vue';
import ItemPriceList from '../item-price/ItemPriceList.vue';

export default {
  data() {
    return {};
  },
  components: {
    PaymentMethodList,
    CurrencyList,
    ItemCategoryList,
    TicketStatusList,
    PriceBookList,
    ItemList,
    ItemPriceList,
  },
};
</script>

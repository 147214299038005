<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalles de Cita</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="error-pages" v-if="!objForm.id_paciente">
                <div class="main-wrapper error-wrapper">
                  <div class="error-box">
                    <h3>
                      <img class="img-fluid mb-0" src="@/assets/img/icons/danger.svg" alt="Logo" />
                      Paciente no encontrado
                    </h3>
                    <p>Es posible que no haya seleccionado el paciente correctamente, intente nuevamente seleccionando el paciente. </p>
                    <b-button @click="" variant="btn btn-danger previous me-2">Cancelar</b-button>
                  </div>
                </div>
              </div>
              
              <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false && objForm.id_paciente">
                <div :class="field.class">
                  <div class="input-block local-forms">
                    <template v-if="field.type === 'text' || field.type == 'date' || field.type == 'time'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <input
                        class="form-control"
                        :type="field.type"
                        v-model="objForm[field.name]"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :disabled="field.disabled"
                      />
                    </template>
                    <template v-if="field.type === 'textarea'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <b-form-textarea
                        id="textarea"
                        v-model="objForm[field.name]"
                        rows="5"
                        max-rows="6"
                        placeholder="Ingresa el texto aqui">
                      </b-form-textarea>
                    </template>
                    <template v-if="field.type === 'select'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <vue-select
                        :options="field.options || []"
                        v-model="objForm[field.name]"
                        placeholder="Selecciona una opción"
                        @select="handleSelectField(field.name, $event)"
                        :disabled="field.disabled"
                        :required="field.required"
                      />
                    </template>
                  </div>
                </div>
              </template>
              <div class="d-flex" v-if="objForm.id_paciente">
                <b-button @click="handleCancel" variant="btn btn-danger previous me-2">Cancelar</b-button>
                <b-button type="submit" variant="btn btn-primary next" v-if="bGuardar == false">Guardar</b-button>
                  <b-button variant="btn btn-primary next" v-if="bGuardar" >
                    <b-spinner class="spinner-border-sm me-1"></b-spinner>
                    Cargando...
                  </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import { notification } from "ant-design-vue";
import dayjs from 'dayjs';
import locale from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Spinner from "@/components/Spinner.vue";
import { addHours } from "date-fns";


dayjs.extend(locale);
//dayjs.locale('es');
dayjs.extend(customParseFormat);

const lstFields = [
{ name: "fecha_inicio", label: "Fecha Inicio", type: 'date', required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "hora_inicio", label: "Hora Inicio", type: 'time', required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "id_categoria_agenda", label: "Categoria", type: 'select', options: [], required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "id_estatus_agenda", label: "Estatus", type: 'select', options: [], required: true, class: "col-12 col-md-6 col-xl-4"},
{ name: "notas", label: "Notas", type: 'textarea'},
];

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      dtHoy: dayjs(),
      objForm: this.handleInitForm(),
      lstFields,
      lstPacientes: [],
      dtHoraInicio: '',
    };
  },
  watch: {
    objForm: {
      handler(newValue, oldValue) {
        //this.$emit('onCita', newValue);
      },
      deep: true,
    },
    id(newValue, oldValue) {
      this.objForm.id_paciente = newValue;
    },
  },
  components: {
    Spinner,
  },
  async created() {
    this.objForm = this.handleInitForm();

    await this.getData();
  },
  methods: {
    handleInitForm(){
      return {
        fecha_inicio: "",
        hora_inicio: "",
        id_categoria_agenda: "",
        id_estatus_agenda: "",
        notas: "",
      };
    },
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/catalogos/citas`);
        let objDataCategoria = objResponse.data.categoriasAgenda;
        let objDataEstatus = objResponse.data.estatusAgenda;

        this.lstFields.find(objField => objField.name === "id_categoria_agenda").options =
          objDataCategoria.map(objItem => ({ id: String(objItem.id_categoria_agenda), text: String(objItem.nombre) }));

        this.lstFields.find(objField => objField.name === "id_estatus_agenda").options =
          objDataEstatus.map(objItem => ({ id: String(objItem.id_estatus_agenda), text: String(objItem.nombre) }));
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }
      
      this.objForm[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    async submitForm() {
      if(!this.validateForm()) return;
      this.bGuardar = true;
      const dtHoraInicio = this.dtHoy.hour(parseInt(this.objForm.hora_inicio.split(':')[0], 10))
        .minute(parseInt(this.objForm.hora_inicio.split(':')[1], 10));
      this.objForm.hora_inicio = dayjs(dtHoraInicio).toDate();
      this.objForm.hora_fin = addHours(this.objForm.hora_inicio, 1);
      try{
        await axios.post("/agendas", this.objForm);
        this.openNotification('Cita registrado correctamente');
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar consulta:', error);
      }
      this.bGuardar = false;
      
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    openNotification(strMessage) {
      notification.open({
        message: strMessage,
        placement: "bottomRight",
        bottom: "50px",
        duration: 3,
        style: {
          width: "250px",
          marginLeft: `${335 - 600}px`,
          background: "#2ab57d",
        },
      });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table show-entire">
        <div class="card-body">
          <!-- Table Header -->
          <div class="page-table-header mb-2">
            <div class="row align-items-center">
              <div class="col">
                <div class="doctor-table-blk">
                  <h3>Alergias Paciente</h3>
                  <!--div class="doctor-search-blk">
                    <div class="top-nav-search table-search-blk">
                      <form @submit.prevent="handleSearch">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Buscar"
                          v-model="strSearchQuery"
                        />
                        <a class="btn"
                          ><img src="@/assets/img/icons/search-normal.svg" alt=""
                        /></a>
                      </form>
                    </div>
                  </div-->
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <!-- Table -->
          <div class="table-responsive">
            <a-table
                class="table border-0 custom-table comman-table datatable mb-0"
                :columns="lstColumns"
                :dataSource="handleFilterData"
                :rowSelection="handleRowSelection"
                :rowKey="record => record.id_alergia"
                :pagination="{ pageSize: 15 }"
                :loading="bSpinner"
              >
                <template v-slot:bodyCell="{ column, record, index }">
                  <template v-if="column.editable">
                    <a-input
                      v-model="record[column.dataIndex]"
                      @change="handleChange(record, column.dataIndex, $event)"
                      :value="record[column.dataIndex]"
                      :allowClear="true"
                    />
                  </template>
                  <template v-else>
                    {{ record[column.dataIndex] }}
                  </template>
                </template>
              </a-table>
              <div class="col-12">
                <b-button @click="submitForm" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
          </div>
          <!-- /Table -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";

function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
          const bValueStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
      editable: objColumn.bEdit || false,
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "Nombre", strDataIndex: "nombre", strKey: "nombre", bFilter: true, bEdit: false },
    { strTitle: "Descripcion", strDataIndex: "descripcion", strKey: "descripcion", bFilter: false, bEdit: true },
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      strSearchQuery: "",
      lstColumns,
      lstRowSelected,
      lstData,
      lstColumnsToCreate,
      lstAlergias: [],
    };
  },
  emits: ['submit', 'cancel', 'select'],
  computed: {
    handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
      const query = this.strSearchQuery.toLowerCase();

      return this.lstData.filter((objData) => {
        return this.lstColumnsToCreate.some((objColumn) => {
          if (!objColumn.bFilter) return false;
          const strValue = objData[objColumn.strDataIndex];
          const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
          return strValueSearch.includes(query);
        });
      });
    },
    handleRowSelection(){
      return {
        selectedRowKeys: this.lstRowSelected,
        onSelect: (record, selected) => {
          record.activo = selected ? 1 : 0;
          if(selected){
            this.lstRowSelected.push(record.id_alergia);
            const objAlergia = this.lstData.find((objAlergia) => objAlergia.id_alergia === record.id_alergia);
            const isAlreadyInList = this.lstAlergias.some((alergia) => alergia.id_alergia === record.id_alergia);
            if(!isAlreadyInList) this.lstAlergias.push(objAlergia);
            if(isAlreadyInList) this.lstAlergias = this.lstAlergias.filter((alergia) => alergia.id_alergia !== record.id_alergia);
          }

          if(!selected) {
              if (!record.id_alergia_paciente) 
                this.lstAlergias = this.lstAlergias.filter((objAlergia) => objAlergia.id_alergia !== record.id_alergia);
              if (record.id_alergia_paciente) 
                this.lstAlergias.push(this.lstData.find((objAlergia) => objAlergia.id_alergia === record.id_alergia));
              
              this.lstRowSelected = this.lstRowSelected.filter((id) => id !== record.id_alergia);
          }
          this.lstAlergias = Array.from(new Set(this.lstAlergias.map(a => a.id_alergia))).map(id => this.lstAlergias.find(a => a.id_alergia === id));
        }
      }
    }
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/pacientes/${this.id}/alergias/list`);
        this.lstData = objResponse.data.lstAlergias.map((objAlergia) => {
          const objAlergiaPaciente = objResponse.data.lstAlergiasPaciente.find((obj) => obj.id_alergia === objAlergia.id_alergia);
          return {
            id_alergia_paciente: objAlergiaPaciente ? objAlergiaPaciente.id_alergia_paciente : null,
            id_paciente: this.id,
            id_alergia: objAlergia.id_alergia,
            nombre: objAlergia.nombre,
            descripcion: objAlergiaPaciente && objAlergiaPaciente.descripcion ? objAlergiaPaciente.descripcion : '',
            activo: objAlergiaPaciente && objAlergiaPaciente.activo ? 1 : 0,
            bEdit: false,
          };
        });        
        this.lstRowSelected = this.lstData.reduce((lstIds, objAlergia) => {
          if(objAlergia.activo) lstIds.push(objAlergia.id_alergia);
          return lstIds;
        }, []);
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    handleChange(record, columnName, event) {
      if(!this.lstRowSelected.includes(record.id_patologia)) this.lstRowSelected.push(record.id_patologia);
      record[columnName] = event.target.value;
      const objPatologia = this.lstData.find((objPatologia) => objPatologia.id_patologia === record.id_patologia);
      if(objPatologia){
        const existingIndex = this.lstPatologias.findIndex((patologia) => patologia.id_patologia === record.id_patologia);
        if (existingIndex === -1) this.lstPatologias.push({ ...objPatologia });
        if (existingIndex !== -1) this.lstPatologias[existingIndex] = { ...objPatologia };
      }
    },
    handleRowSelectionChange(selectedRowKeys) {
      this.lstRowSelected = selectedRowKeys;
    },
    async submitForm() {
      this.bGuardar = true;
      try{
        if(this.lstAlergias) await axios.post("/alergias-pacientes/list", this.lstAlergias);
        this.toast('success', true, 'Alergia registradas correctamente');
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar la alergia:', error);
        this.toast('error', true, 'Ocurrio un error al registrar la alergia, intente nuevamente.');
      }
      this.bGuardar = false;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
<template>
  <div class="invoice-add-table">
    <h4>Detalle de Articulos</h4>
    <div class="table-responsive">
      <table class="table table-center add-table-items">
        <thead>
          <tr>
            <th>Articulo</th>
            <th>Categoria</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Descuento</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="add-row" v-for="(objItem, index) in lstCarrito" :key="objItem.id">
            <td>
              <div class="w-100">
                <vue-select
                  :options="lstArticulos || []"
                  v-model="objItem.id_articulo"
                  placeholder="Articulo"
                  :clearable="true"
                  @select="handleUpdateItem(objItem)"
                />
              </div>
            </td>
            <td>
              <input class="form-control" type="text" :value="objItem.categoria_articulo" disabled />
            </td>
            <td>
              <input class="form-control" type="number" v-model="objItem.cantidad" :min="0" @input="handleCalculate(objItem)" :disabled="!objItem.categoria_articulo"/>
            </td>
            <td>
              <input class="form-control" type="text" v-model="objItem.precio" :min="0" disabled />
            </td>
            <td>
              <input  class="form-control" type="number" v-model="objItem.descuento" :min="0" @input="handleCalculate(objItem)" :disabled="!objItem.categoria_articulo"/>
            </td>
            <td>
              <input type="text" class="form-control" :value="objItem.total" :min="0" disabled />
            </td>
            <td class="add-remove text-end">
              <a href="javascript:void(0);" class="add-btns me-2" @click="handleAdd">
                <i class="fas fa-plus-circle"></i>
              </a>
              <a href="javascript:void(0);" class="remove-btn" @click="handleDelete(objItem)">
                <i class="fa fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  props: {
    objData: { type: Object, default: () => {} },
  },
  data() {
    return {
      lstCarrito: [],
      lstItemsDelete: [],
      lstArticulos: [],
    };
  },
  watch: {
    objData: {
      handler: "handleDataInit",
      immediate: true,
    },
  },
  emits: ["changeItem"],
  created() {
    this.handleAdd();
    this.handleCatalogos();
    this.handleDataInit();
  },
  methods: {
    async handleDataInit() {
      try {
        if(this.objData.id_ticket) {
          const objResponse = await axios.get(`/articulos-tickets?ticket=${this.objData.id_ticket}`);
          this.lstCarrito = objResponse.data.map(item => ({
            id_articulo_ticket: item.id_articulo_ticket,
            id_ticket: item.id_ticket,
            id_articulo: String(item.id_articulo),
            cantidad: item.cantidad || 1,
            precio: parseFloat(item.precio) ?? parseFloat(0.00),
            descuento: parseFloat(item.descuento) ?? parseFloat(0.00),
            total: parseFloat(item.total) ?? parseFloat(0.00),
            categoria_articulo: item.articulo.categoria_articulo.nombre ?? '',
            delete: false,
          }));
          this.handleChange()
        }
        if(this.lstCarrito.length === 0) this.handleAdd();
      } catch (err) {
        console.error(err);
      }
    },
    async handleCatalogos() {
      try {
        const objResponse = await axios.get(`/precios-articulos?lista_precio=${this.objData.id_lista_precio}`);
        this.lstArticulos = objResponse.data.map(objItem => ({
          ...objItem,
          id: String(objItem.id_articulo),
          text: String(objItem.articulo.nombre),
        }));
      } catch (e) {
        console.log(e);
      }
    },
    handleUpdateItem(objItem) {
      const objSelected = this.lstArticulos.find((objArticulo) => parseInt(objArticulo.id_articulo) === parseInt(objItem.id_articulo));
      if (objSelected) {
        objItem.categoria_articulo = objSelected.articulo.categoria_articulo.nombre;
        objItem.precio = objSelected.precio;
        this.handleCalculate(objItem);
      }
    },
    handleCalculate(objItem) {
      const total = (objItem.precio * objItem.cantidad) - objItem.descuento;
      objItem.total = total > 0 ? total : 0;
      this.handleChange()
    },
    handleChange(){
      const objCarrito = {
        lstCarrito: this.lstCarrito,
        lstItemsDelete: this.lstItemsDelete,
      }
      this.$emit("changeItem", objCarrito);
    },
    handleDelete(objItem) {
      const index = this.lstCarrito.indexOf(objItem);
      if (index !== -1) this.lstCarrito.splice(index, 1);

      objItem.isDeleted = true;
      this.lstItemsDelete.push(objItem);
      this.handleChange();
      if (this.lstCarrito.length === 0) this.handleAdd();
    },
    handleAdd() {
      this.lstCarrito.push({
        id: this.lstCarrito.length + 1,
        id_articulo: null,
        articulo: "",
        categoria_articulo: "",
        cantidad: 1,
        precio: 0.00,
        descuento: 0,
        total: 0.00,
        delete: false,
      });
    },
  },
};
</script>

<template>
  <div class="modal custom-modal fade bank-details" id="new_ticket_form" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          
        </div>
        <div class="modal-body">
          <div class="bank-inner-details">
            <physiocloud-component-form
              ref="formModal"
              :bEdit="bEdit"
              :strFormTitle="strFormTitle"
              :strRecordId="strRecordId"
              :strApiRoute="strApiRoute"
              :lstFields="lstFields"
              :objForm="objForm"
              :bSaveButton="false"
              :bCancelButton="false"
              @submit="handleSubmit" 
              @cancel="handleCancel"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="bank-details-btn">
            <a
              ref="cancelButton"
              href="javascript:void(0);"
              data-bs-dismiss="modal"
              class="btn bank-cancel-btn me-2">Cancelar</a>
              <a href="javascript:void(0);" class="btn bank-save-btn" @click="handleClick">Guardar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "@/axios";


const lstFields = [
  { name: "id_paciente", label: "Paciente", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-6"},
  { name: "id_lista_precio", label: "Lista de Precios", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-6"},
  { name: "id_forma_pago", label: "Forma de pago", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-6"},
  { name: "id_estatus_ticket", label: "Estatus", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-6"},
];

export default {
  props: {
    isEdit: { type: Boolean, default: false },
    recordId: { type: String, default: null },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strRecordId: this.recordId,
      strPath: "/settings/pos",
      strFormTitle: "Formulario Ticket",
      strApiRoute: "/tickets",
      lstFields: lstFields,
      objForm: {},
    };
  },
  emits: ['submit', 'cancel', 'select'],
  async created() {
    this.handleInitForm();
    this.handleCatalogos();
    this.handlePacientes();
    await this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        nombre: "",
        codigo: "",
        descripcion: "",
        activo: "1",
        id_lista_precio: "1",
        id_forma_pago: "1",
        id_estatus_ticket: "1",
      };
    },
    async handleCatalogos(){
      try{
        const objResponse = await axios.get("/tickets/catalogos");
        this.handleSetValuesList("id_lista_precio", "id_lista_precio", objResponse.data.lista_precios);
        this.handleSetValuesList("id_forma_pago", "id_forma_pago", objResponse.data.formas_pago);
        this.handleSetValuesList("id_estatus_ticket", "id_estatus_ticket", objResponse.data.estatus_tickets);        
      }catch(err){
        console.error(err);
      }
    },
    async handlePacientes(){
      try{
        const objResponse = await axios.get("/pacientes");
        const lstPacientes = objResponse.data.map(objPaciente => ({
          ...objPaciente,
          nombre: `${objPaciente.nombre ?? ''} ${objPaciente.apellido_paterno ?? ''} ${objPaciente.apellido_materno ?? ''}`,
        }));
        this.handleSetValuesList("id_paciente", "id_paciente", lstPacientes);
      }catch(err){
        console.error(err);
      }
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`${this.strApiRoute}/${this.strRecordId}`);
        this.objForm = objResponse.data;
        this.objForm.id_paciente = String(this.objForm.id_paciente);
        this.objForm.id_lista_precio = String(this.objForm.id_lista_precio);
        this.objForm.id_forma_pago = String(this.objForm.id_forma_pago);
        this.objForm.id_estatus_ticket = String(this.objForm.id_estatus_ticket);
        this.objForm.activo = this.objForm.activo ? "1" : "0";
      }
    },
    handleSetValuesList(strFieldName, strKeyField, lstValues){
      this.lstFields.find(objField => objField.name === strFieldName).options = lstValues.map(objValue => ({
        id: String(objValue[strKeyField]),
        text: String(objValue.nombre),
      }));
    },
    handleClick(){
      this.$refs.formModal.submitForm();
    },
    async handleSubmit(objForm){
      await this.$refs.cancelButton.click();
      this.$emit('submit', objForm);
    },
    handleCancel(){
      const modalElement = document.getElementById("new_ticket_form");
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    }
  }
};
</script>

<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb title="Tickets" text="Formulario de Ticket" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";

const lstFields = [
  { name: "id_paciente", label: "Paciente", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_lista_precio", label: "Lista de Precios", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_forma_pago", label: "Forma de pago", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_estatus_ticket", label: "Estatus", type: 'select', options: [], required: true, strClass: "col-12 col-md-6 col-xl-4"},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strRecordId: this.recordId,
      strPath: "/settings/pos",
      strFormTitle: "Formulario Ticket",
      strApiRoute: "/tickets",
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleInitForm();
    this.handleCatalogos();
    this.handlePacientes();
    await this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        nombre: "",
        codigo: "",
        descripcion: "",
        activo: "1",
      };
    },
    async handleCatalogos(){
      try{
        const objResponse = await axios.get("/tickets/catalogos");
        this.handleSetValuesList("id_lista_precio", "id_lista_precio", objResponse.data.lista_precios);
        this.handleSetValuesList("id_forma_pago", "id_forma_pago", objResponse.data.formas_pago);
        this.handleSetValuesList("id_estatus_ticket", "id_estatus_ticket", objResponse.data.estatus_tickets);        
      }catch(err){
        console.error(err);
      }
    },
    async handlePacientes(){
      try{
        const objResponse = await axios.get("/pacientes");
        const lstPacientes = objResponse.data.map(objPaciente => ({
          ...objPaciente,
          nombre: `${objPaciente.nombre ?? ''} ${objPaciente.apellido_paterno ?? ''} ${objPaciente.apellido_materno ?? ''}`,
        }));
        this.handleSetValuesList("id_paciente", "id_paciente", lstPacientes);
      }catch(err){
        console.error(err);
      }
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`${this.strApiRoute}/${this.strRecordId}`);
        this.objForm = objResponse.data;

        this.objForm.activo = this.objForm.activo ? "1" : "0";
      }
    },
    handleSetValuesList(strFieldName, strKeyField, lstValues){
      this.lstFields.find(objField => objField.name === strFieldName).options = lstValues.map(objValue => ({
        id: String(objValue[strKeyField]),
        text: String(objValue.nombre),
      }));
    },
    handleSubmit(objForm){
      console.log(objForm);
      this.$router.push({ name: "/ticket/edit", params: { recordId: objForm.id_ticket } });
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>

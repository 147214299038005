<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-heading">
                <h4>Información basica</h4>
              </div>
            </div>
            <template v-for="(field, index) in lstFields" :key="index">
              <div :class="field.strClass">
                <div class="input-block local-forms">
                  <template v-if="field.type !== 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                      class="form-control"
                      :type="field.type"
                      v-model="objForm[field.name]"
                      :placeholder="field.placeholder"
                      :required="field.required"
                      :disabled="field.disabled"
                      @change="handleInputField(field.name, $event, 'input')"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleInputField(field.name, $event, 'select')"
                      :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

const lstFields = [
  { name: "nombre", label: "Nombre", type: "text", required: true, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "apellido_paterno", label: "Apellido Paterno", type: "text", required: true, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "apellido_materno", label: "Apellido Materno", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "fecha_nacimiento", label: "Fecha de Nacimiento", type: "date", required: true, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "id_genero", label: "Género", type: "select", required: true, options: [], strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "telefono_celular", label: "Teléfono Celular", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "telefono_casa", label: "Teléfono de Casa", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
  { name: "correo_electronico", label: "Correo Electrónico", type: "email", required: false, strClass: "col-12 col-md-6 col-xl-4", bPersonal: true },
];

export default {
  props: {
    objData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lstCatalagos: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      lstFields,
      objForm: {},
    };
  },
  emits: ['paciente'],
  watch: {
    objData: {
      handler: function (value) {
        this.objForm = value;
      },
      immediate: true,
    },
    lstCatalagos: {
      handler: function (value) {
        if(value.generos) this.updateFieldOptions('id_genero', 'nombre', value.generos);
      },
      immediate: true,
    },
  },
  async created() {
    this.objForm = this.objData;
  },
  methods: {
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
    },
    handleInputField(strFieldName, event, strType) {
      const strValue = strType === 'input' ? event.target.value : event.id;
      this.objForm[strFieldName] = strValue;
      this.$emit('paciente',  this.objForm);
    },
  },
};
</script>

<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />

      <ItemPriceList />
    </div>
  </div>
<delete></delete>
</template>
<script>
import ItemPriceList from "./ItemPriceList.vue";

export default {
  data() {
    return {
      strTitle: "Precios de Articulos",
      strSubutitle: "Lista de Precios de Articulos",
      strPath: "settings/pos/",
    };
  },
  components: {
    ItemPriceList,
  },
  created() {
    
  },
  methods: {

  }
};
</script>

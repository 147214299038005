<template>
  <div class="row">
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon1.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">{{ handleFormat(objReport.todos.total) }}</div>
            </div>
          </div>
          <p class="inovices-all">Total Tickets <span>{{ objReport.todos.cantidad }}</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon2.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">{{ handleFormat(objReport.pagados.total) }}</div>
            </div>
          </div>
          <p class="inovices-all">Tickets Pagados <span>{{ objReport.pagados.cantidad }}</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon3.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">{{ handleFormat(objReport.pendientes.total) }}</div>
            </div>
          </div>
          <p class="inovices-all">Tickets Pendientes <span>{{ objReport.pendientes.cantidad }}</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon4.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">{{ handleFormat(objReport.cancelados.total) }}</div>
            </div>
          </div>
          <p class="inovices-all">Tickets Cancelados <span>{{ objReport.cancelados.cantidad }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template><script>
import axios from "@/axios";
const objReport = {
  pagados: { total: 0, cantidad: 0 },
  pendientes: { total: 0, cantidad: 0 },
  cancelados: { total: 0, cantidad: 0 },
  todos: { total: 0, cantidad: 0 },
};

export default {
  data() {
    return {
      bLoading: false,
      objReport,
    };
  },
  async created() {
    await this.handleDataInit();
  },
  methods: {
    async handleDataInit() {
      try {
        const objResponse = await axios.get(`/tickets/reporte/estatus`);
        this.objReport = objResponse.data;
      } catch (error) {
        console.error(error);
      }
    },
    handleFormat(value) {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(value));
    },
  },
};
</script>
